
body {
  font-family: "Open Sans", sans-serif;
  color: rgb(0,74,124);
}

.App {
  background-color:  rgb(0,74,124);  
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.brand {
  color:  rgb(148, 214, 220);
  font-family: Rajdhani-Bold;
  font-size: 2.1vmax;
  margin-left: 0.5em;
  display: inline;
}

.brand sup {
  top: -0.5vmax;
}

#logoutBtn {
  color:  rgb(148, 214, 220);
  background-color: transparent;
  border-color:  rgb(148, 214, 220);
  font-family: Rajdhani-Bold;
  font-size: 1vmax;
  margin-right: 0.75vw;
  display: inline;
  position: absolute;
  right: 0;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

#studentContainer {
  width: 100%;
}

#divider {
  display: none;
}

@media only screen and (orientation: landscape) {
  #divider {
    display: inherit;
    width: 1vw;
    padding: 0;
    flex: 0 0 auto;
  }

  #studentContainer {
    display: flex;
  }
}

#headerContainer {
  text-align: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
  display: inline-block;
}

.popup-content {
  border-radius: 0.5vw;
}

.popup {
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
}

.popupHeader {
  color: rgb(0,74,124); 
  background-color: rgb(148, 214, 220);
  height: 20%;
  font-weight: bold;
  padding-left: 0.5em;
  border-radius: 0.5vw 0.5vw 0 0;
}

.popupClose {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0.5vw;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  background-color: white;
  cursor: pointer;
  padding: 0.5em;
}

.popupContent {
  margin: 0.5em;
}

.table td {
  vertical-align: middle;
  padding: 0.5em 0;
}

.tableContainer {
  clear: both;
  position: relative;
}

.headerBackground {
  background-color: #fff;
}

.innerTable {
  overflow-y: scroll;
  overflow-x: hidden; 
  border-top: 0.1em solid #dee2e6;
}

.tableHeader {
  position: absolute;
  top: 0;
}

.tableHeader button {
  background-color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  color: rgb(0,74,124);
}

.tableHeader p {
  cursor: default;
}

.table th, .table th button {
  padding: 0;
  line-height: normal;
}

.table {
  table-layout: fixed;
}

#studentList {
  margin-left: 0.75vw;
  background-color: white;
  height: 20vh;
  width: 98vw;
  min-width: 15em;
  display: inline-block;
  border-radius: 0.5vw;
  overflow-x: scroll;
  overflow-y: hidden;
  font-size: 1.75vh;
}

.header {
  width: 98%;
  display: flex;
  margin: 2%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

#studentSearch {
  margin-left: 0.25vw;
  position: relative;
  display: flex;
}

#studentSearchText, #studentSearchButton, #searchClear {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  padding: 0.5vw;
  color: rgb(0,74,124);
}

#studentLabel {
  margin: 0;
  font-family: Rajdhani-Bold;
  font-size: 1.75vmax;
}

#studentSearchText {
  border-radius: 0.5vw 0 0 0.5vw;
  border-right: none;
}

#searchClear {
  position: absolute;
  right: 4vw;
  border-left: none;
  border-right: none;
  background-color: transparent;
  cursor: pointer;
  border-color: transparent;
}

#studentSearchButton {
  background-color: white;
  border-radius: 0 0.5vw 0.5vw 0;
  cursor: pointer;
}

.listTableContainer {
  padding-top: 1em;
  min-width: 20em;
}

.listInnerTable {
  height: 12.5vh;
}

.listTableHeader button {
  width: 2vw;
}

.fa-square-o, .fa-circle-o, .fa-times-circle {
  color: red;
}

.fa-square, .fa-circle, .fa-check-circle {
  color: green;
}

.fa-minus-circle {
  color: orange;
}

.fa-question-circle {
  color: gray;
}

.emptyTriangle {
  font-size: 1.25em;
  color: red;
  margin: 0 0.09em;
}

#currentStudent {
  background-color: rgb(148, 214, 220);
}

.listInnerTable tr {
  cursor: pointer;
}

.header p {
  margin: 0;
  font-family: Rajdhani-Bold;
}

.listTableHeader p, .listTableHeader button {
  font-size: 0.9em;
  background: transparent;
}

.studentActionSymbols p {
  display: inline;
}

.studentActionSymbols {
  white-space: nowrap;
  text-align: center;
}

.PassPredictIcon {
  width: 2vw;
}

.Student {
  width: 4vw;
}

.RecAction {
  width: 5vw;
}

.Actions {
  width: 2vw;
}

.unseen td:nth-child(3) {
  font-weight: bold;
}

#checkFilters {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#checkFilters input {
  vertical-align: middle;
}

#checkFilters label {
  margin-top: 0.5vh;
  margin-bottom: 0 !important;
}

@media only screen and (orientation: landscape) {
  #studentList {
    height: 90vh;
    width: 25vw;
    font-size: 0.9vmax;
  }

  .listInnerTable {
    height: 75vh;
  }

  .listInnerTable td:nth-child(1) {
    padding-left: 0.5em;
  }
}

#studentInfo {
  background-color: white;
  height: 72.5vh;
  width: 100vw;
  display: inline-block;
  border-radius: 0.5em;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  font-size: 1.5vh;
  margin-right: 0.75vw;
}

#nameHeader {
  font-family: Rajdhani-Bold;
  margin: 0;
  font-size: 2vmax;
}

.progress {
  margin-left: 0.5vw;
  margin-bottom: 1vh;
}

.progress-bar-success{
  background-color: green;
}

.progress-bar-warning{
  background-color: orange;
}

.progress-bar-danger{
  background-color: red;
}

#pass-prediction-text {
  margin-left: 0.5vw;
  margin-bottom: 1vw;
  font-size: 1.75vmin;
}

#legends {
  border: 1px solid rgb(0,74,124);
  border-radius: 0.5vw;
  display: flex;
  float: left;
  flex-shrink: 1;
  margin-top: 1vh;
  margin-left: 0.5vw;
  margin-bottom: 2vh;
  min-width: 0;
  padding-right: 0.25vw;
}

#headerWrapper {
  height: 12.5vh;
}

#studentHeader {
  right: 2vw;
  width: 25vw;
  position: absolute;
  margin-top: 0.5vh;
  margin-right: 0.5vw;
  text-align: center;
}

#recommendationForm {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  width: 100%;
  order: 2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-self: flex-start;
}

#recommendationForm label {
  font-weight: bold;
}

#recommendationSelect, #notes {
  border-radius: 0.5vw;
  padding: 0.5vw;
  color: rgb(0,74,124);
  display: block;
  border-color: lightgray;
}

#notes {
  border-style: solid;
  border-width: 1px;
  resize: none;
  width: 40vw;
  height: 5vh;
}

.infoTableContainer {
  padding-top: 3.5vh;
  font-size: 1.5vh;
  height: 25vh;
}

.infoTableHeader p, .infoTableHeader button {
  text-align: center;
  background: transparent;
}

.infoInnerTable {
  height: 30vh;
}

#submit {
  display: block;
  border-radius: 0.5vw;
  padding: 0.5vw 1vw;
  color: rgb(148, 214, 220);
  border: transparent;
  background-color: rgb(0,74,124);
  cursor: pointer;
  height: 4vh;
  font-size: 1.5vh;
}

#submit:disabled, #recommendationSelect:disabled {
  background-color: rgb(235, 235, 228);
  color: grey;
  cursor: default;
}

#formTableContainer {
  margin-top: 2vh;
  height: 50vh;
  display: flex;
  flex-wrap: wrap;
}

.SRDate, .IRDate {
  width: 12%;
}

.SystemRecommendation {
  width: 16%;
}

.SystemRec {
  width: 14%;
}

.PassPrediction {
  width: 11%;
}

.PassPred {
  width: 10%;
}

.InstructorRecommendation {
  width: 17%;
}

.InstructorRec {
  width: 17%;
}

.Notes {
  width: 10%;
}

#recommendationForm label, #submit {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

#emptyTriangleLegendWrapper p {
  display: inline;
}

#emptyTriangleLegendWrapper {
  position: relative;
  top: -0.3em;
}

.dateButton {
  border: none;
  background: transparent;
  color: rgb(0,74,124);
  cursor: pointer;
  padding: 0;
}

.srDateButton {
  margin: 0 0 0 0.2vw;
}

.infoTableHeader button {
  white-space: pre;
}

@media only screen and (orientation: landscape) {
  #studentInfo {
    height: 90vh;
    width: 72.5vw;
    font-size: 0.9vmax;
  }

  .infoInnerTable {
    height: 55vh;
  }

  .infoTableContainer {
    top: 0px;
    font-size: 0.9vmax;
    padding-top: 1em;
    padding-right: 0.5vw;
    order: 2;
  }

  .SRDate, .IRDate {
    width: 7%;
  }
  
  .Notes {
    width: 12%;
  }

  .infoTableHeader button {
    white-space: nowrap;
  }

  #recommendationForm {
    order: 1;
    display: block;
    height: 60vh;
  }

  #formTableContainer {
    flex-wrap: nowrap;
    height: 60vh;
  }

  #notes {
    width: 11vw;
    height: 20vh;
  }
}
.react-calendar-timeline {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
  clear: both;
}

.react-calendar-timeline .rct-sidebar-header {
  background: none;
  color: rgb(0,74,124);
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  padding: 0 4px;
}

.rct-top-header, .rct-label-group, .rct-label {
  height: 3vh !important;
  line-height: 3vh !important;
}

.rct-header {
  overflow-y: hidden;
  height: 6vh !important;
}

.rct-label-group {
  background: rgb(0,74,124) !important;
  color:rgb(148, 214, 220) !important;
}

.rct-label {
  color: rgb(0,74,124) !important;
}

.session {
  cursor: default !important;
}

.passSession {
  background: green !important;
  border: 1px solid green !important;
}

.undecidedSession {
  background: orange !important;
  border: 1px solid orange !important;
}

.failSession {
  background: red !important;
  border: 1px solid red !important;
}

.noneSession {
  background: gray !important;
  border: 1px solid gray !important;
}

.rct-vl {
  background: transparent !important;
  z-index: 1 !important;
}

.rct-horizontal-lines div {
  z-index: 1 !important;
  position: relative;
}

.rct-outer {
  background: rgba(200, 200, 200, 0.5) !important;
}

.itemContainer {
  position: absolute;
}

.timelinePassPretest, .timelineFailPretest, .timelineFailFinal {
  box-sizing: border-box;
  position: absolute;
}

.timelinePassPretest, .timelineFailPretest {
  font-size: 2.1vh !important;
  z-index: 10;
  left: -0.8vw !important;
}

.timelineFailFinal {
  font-size: 3vh;
  z-index: 10;
  left: -0.9vw !important;
}

.expectedRange {
  border: none !important;
  background: rgb(255, 255, 255) !important;
  height: 9vh !important;
  top: 0 !important;
  z-index: 1 !important;
}

#timelineControls {
  text-align: right;
  margin-right: 0.75vw;
}

#timelineControls button {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  background-color: white;
  cursor: pointer;
  color: rgb(0,74,124);
}

#timelineLeft {
  border-radius: 0.5vw 0 0 0;
}

#timelineDefault {
  border-radius: 0 0.5vw 0 0;
}

.tooltip {
  opacity: 1 !important;
  white-space: pre-line;
}

.extraTooltipSpace {
  position: absolute;
  height: 100%;
  width: calc(100% + 2em);
  left: -1em;
}
/* Attaching Tutorgen fonts */
@font-face {
  font-family: OpenSans-Light;
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format('truetype');
}
  
/* Attaching Tutorgen fonts */
@font-face {
  font-family: Rajdhani-Bold;
  src: url(/static/media/Rajdhani-Bold.d00c405a.ttf) format('truetype');
}

#welcome {
  position: absolute;
  font-family: Rajdhani-Bold;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20%;
  margin: auto;
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#welcome sup {
  font-size: 60%;
}

#welcome h1 {
  color: rgb(0,74,124);
}

#welcome h4 {
  color: rgb(0,74,124);
}

#qsLoginBtn {
  background-color: rgb(148, 214, 220);
  border-color: rgb(0,74,124);
  color: rgb(0,74,124);
}

.body {
  background-color:  rgb(0,74,124);
}

