#studentList {
  margin-left: 0.75vw;
  background-color: white;
  height: 20vh;
  width: 98vw;
  min-width: 15em;
  display: inline-block;
  border-radius: 0.5vw;
  overflow-x: scroll;
  overflow-y: hidden;
  font-size: 1.75vh;
}

.header {
  width: 98%;
  display: flex;
  margin: 2%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

#studentSearch {
  margin-left: 0.25vw;
  position: relative;
  display: flex;
}

#studentSearchText, #studentSearchButton, #searchClear {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  padding: 0.5vw;
  color: rgb(0,74,124);
}

#studentLabel {
  margin: 0;
  font-family: Rajdhani-Bold;
  font-size: 1.75vmax;
}

#studentSearchText {
  border-radius: 0.5vw 0 0 0.5vw;
  border-right: none;
}

#searchClear {
  position: absolute;
  right: 4vw;
  border-left: none;
  border-right: none;
  background-color: transparent;
  cursor: pointer;
  border-color: transparent;
}

#studentSearchButton {
  background-color: white;
  border-radius: 0 0.5vw 0.5vw 0;
  cursor: pointer;
}

.listTableContainer {
  padding-top: 1em;
  min-width: 20em;
}

.listInnerTable {
  height: 12.5vh;
}

.listTableHeader button {
  width: 2vw;
}

.fa-square-o, .fa-circle-o, .fa-times-circle {
  color: red;
}

.fa-square, .fa-circle, .fa-check-circle {
  color: green;
}

.fa-minus-circle {
  color: orange;
}

.fa-question-circle {
  color: gray;
}

.emptyTriangle {
  font-size: 1.25em;
  color: red;
  margin: 0 0.09em;
}

#currentStudent {
  background-color: rgb(148, 214, 220);
}

.listInnerTable tr {
  cursor: pointer;
}

.header p {
  margin: 0;
  font-family: Rajdhani-Bold;
}

.listTableHeader p, .listTableHeader button {
  font-size: 0.9em;
  background: transparent;
}

.studentActionSymbols p {
  display: inline;
}

.studentActionSymbols {
  white-space: nowrap;
  text-align: center;
}

.PassPredictIcon {
  width: 2vw;
}

.Student {
  width: 4vw;
}

.RecAction {
  width: 5vw;
}

.Actions {
  width: 2vw;
}

.unseen td:nth-child(3) {
  font-weight: bold;
}

#checkFilters {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#checkFilters input {
  vertical-align: middle;
}

#checkFilters label {
  margin-top: 0.5vh;
  margin-bottom: 0 !important;
}

@media only screen and (orientation: landscape) {
  #studentList {
    height: 90vh;
    width: 25vw;
    font-size: 0.9vmax;
  }

  .listInnerTable {
    height: 75vh;
  }

  .listInnerTable td:nth-child(1) {
    padding-left: 0.5em;
  }
}
