.table td {
  vertical-align: middle;
  padding: 0.5em 0;
}

.tableContainer {
  clear: both;
  position: relative;
}

.headerBackground {
  background-color: #fff;
}

.innerTable {
  overflow-y: scroll;
  overflow-x: hidden; 
  border-top: 0.1em solid #dee2e6;
}

.tableHeader {
  position: absolute;
  top: 0;
}

.tableHeader button {
  background-color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  color: rgb(0,74,124);
}

.tableHeader p {
  cursor: default;
}

.table th, .table th button {
  padding: 0;
  line-height: normal;
}

.table {
  table-layout: fixed;
}
