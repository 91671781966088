.react-calendar-timeline {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
  clear: both;
}

.react-calendar-timeline .rct-sidebar-header {
  background: none;
  color: rgb(0,74,124);
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  padding: 0 4px;
}

.rct-top-header, .rct-label-group, .rct-label {
  height: 3vh !important;
  line-height: 3vh !important;
}

.rct-header {
  overflow-y: hidden;
  height: 6vh !important;
}

.rct-label-group {
  background: rgb(0,74,124) !important;
  color:rgb(148, 214, 220) !important;
}

.rct-label {
  color: rgb(0,74,124) !important;
}

.session {
  cursor: default !important;
}

.passSession {
  background: green !important;
  border: 1px solid green !important;
}

.undecidedSession {
  background: orange !important;
  border: 1px solid orange !important;
}

.failSession {
  background: red !important;
  border: 1px solid red !important;
}

.noneSession {
  background: gray !important;
  border: 1px solid gray !important;
}

.rct-vl {
  background: transparent !important;
  z-index: 1 !important;
}

.rct-horizontal-lines div {
  z-index: 1 !important;
  position: relative;
}

.rct-outer {
  background: rgba(200, 200, 200, 0.5) !important;
}

.itemContainer {
  position: absolute;
}

.timelinePassPretest, .timelineFailPretest, .timelineFailFinal {
  box-sizing: border-box;
  position: absolute;
}

.timelinePassPretest, .timelineFailPretest {
  font-size: 2.1vh !important;
  z-index: 10;
  left: -0.8vw !important;
}

.timelineFailFinal {
  font-size: 3vh;
  z-index: 10;
  left: -0.9vw !important;
}

.expectedRange {
  border: none !important;
  background: rgb(255, 255, 255) !important;
  height: 9vh !important;
  top: 0 !important;
  z-index: 1 !important;
}

#timelineControls {
  text-align: right;
  margin-right: 0.75vw;
}

#timelineControls button {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  background-color: white;
  cursor: pointer;
  color: rgb(0,74,124);
}

#timelineLeft {
  border-radius: 0.5vw 0 0 0;
}

#timelineDefault {
  border-radius: 0 0.5vw 0 0;
}

.tooltip {
  opacity: 1 !important;
  white-space: pre-line;
}

.extraTooltipSpace {
  position: absolute;
  height: 100%;
  width: calc(100% + 2em);
  left: -1em;
}