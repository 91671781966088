
body {
  font-family: "Open Sans", sans-serif;
  color: rgb(0,74,124);
}

.App {
  background-color:  rgb(0,74,124);  
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.brand {
  color:  rgb(148, 214, 220);
  font-family: Rajdhani-Bold;
  font-size: 2.1vmax;
  margin-left: 0.5em;
  display: inline;
}

.brand sup {
  top: -0.5vmax;
}

#logoutBtn {
  color:  rgb(148, 214, 220);
  background-color: transparent;
  border-color:  rgb(148, 214, 220);
  font-family: Rajdhani-Bold;
  font-size: 1vmax;
  margin-right: 0.75vw;
  display: inline;
  position: absolute;
  right: 0;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#studentContainer {
  width: 100%;
}

#divider {
  display: none;
}

@media only screen and (orientation: landscape) {
  #divider {
    display: inherit;
    width: 1vw;
    padding: 0;
    flex: 0 0 auto;
  }

  #studentContainer {
    display: flex;
  }
}

#headerContainer {
  text-align: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
  display: inline-block;
}
