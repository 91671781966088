.popup-content {
  border-radius: 0.5vw;
}

.popup {
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
}

.popupHeader {
  color: rgb(0,74,124); 
  background-color: rgb(148, 214, 220);
  height: 20%;
  font-weight: bold;
  padding-left: 0.5em;
  border-radius: 0.5vw 0.5vw 0 0;
}

.popupClose {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0.5vw;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  background-color: white;
  cursor: pointer;
  padding: 0.5em;
}

.popupContent {
  margin: 0.5em;
}
