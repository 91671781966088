#studentInfo {
  background-color: white;
  height: 72.5vh;
  width: 100vw;
  display: inline-block;
  border-radius: 0.5em;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  font-size: 1.5vh;
  margin-right: 0.75vw;
}

#nameHeader {
  font-family: Rajdhani-Bold;
  margin: 0;
  font-size: 2vmax;
}

.progress {
  margin-left: 0.5vw;
  margin-bottom: 1vh;
}

.progress-bar-success{
  background-color: green;
}

.progress-bar-warning{
  background-color: orange;
}

.progress-bar-danger{
  background-color: red;
}

#pass-prediction-text {
  margin-left: 0.5vw;
  margin-bottom: 1vw;
  font-size: 1.75vmin;
}

#legends {
  border: 1px solid rgb(0,74,124);
  border-radius: 0.5vw;
  display: -ms-flexbox;
  display: flex;
  float: left;
  flex-shrink: 1;
  margin-top: 1vh;
  margin-left: 0.5vw;
  margin-bottom: 2vh;
  min-width: 0;
  padding-right: 0.25vw;
}

#headerWrapper {
  height: 12.5vh;
}

#studentHeader {
  right: 2vw;
  width: 25vw;
  position: absolute;
  margin-top: 0.5vh;
  margin-right: 0.5vw;
  text-align: center;
}

#recommendationForm {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  width: 100%;
  order: 2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-self: flex-start;
}

#recommendationForm label {
  font-weight: bold;
}

#recommendationSelect, #notes {
  border-radius: 0.5vw;
  padding: 0.5vw;
  color: rgb(0,74,124);
  display: block;
  border-color: lightgray;
}

#notes {
  border-style: solid;
  border-width: 1px;
  resize: none;
  width: 40vw;
  height: 5vh;
}

.infoTableContainer {
  padding-top: 3.5vh;
  font-size: 1.5vh;
  height: 25vh;
}

.infoTableHeader p, .infoTableHeader button {
  text-align: center;
  background: transparent;
}

.infoInnerTable {
  height: 30vh;
}

#submit {
  display: block;
  border-radius: 0.5vw;
  padding: 0.5vw 1vw;
  color: rgb(148, 214, 220);
  border: transparent;
  background-color: rgb(0,74,124);
  cursor: pointer;
  height: 4vh;
  font-size: 1.5vh;
}

#submit:disabled, #recommendationSelect:disabled {
  background-color: rgb(235, 235, 228);
  color: grey;
  cursor: default;
}

#formTableContainer {
  margin-top: 2vh;
  height: 50vh;
  display: flex;
  flex-wrap: wrap;
}

.SRDate, .IRDate {
  width: 12%;
}

.SystemRecommendation {
  width: 16%;
}

.SystemRec {
  width: 14%;
}

.PassPrediction {
  width: 11%;
}

.PassPred {
  width: 10%;
}

.InstructorRecommendation {
  width: 17%;
}

.InstructorRec {
  width: 17%;
}

.Notes {
  width: 10%;
}

#recommendationForm label, #submit {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

#emptyTriangleLegendWrapper p {
  display: inline;
}

#emptyTriangleLegendWrapper {
  position: relative;
  top: -0.3em;
}

.dateButton {
  border: none;
  background: transparent;
  color: rgb(0,74,124);
  cursor: pointer;
  padding: 0;
}

.srDateButton {
  margin: 0 0 0 0.2vw;
}

.infoTableHeader button {
  white-space: pre;
}

@media only screen and (orientation: landscape) {
  #studentInfo {
    height: 90vh;
    width: 72.5vw;
    font-size: 0.9vmax;
  }

  .infoInnerTable {
    height: 55vh;
  }

  .infoTableContainer {
    top: 0px;
    font-size: 0.9vmax;
    padding-top: 1em;
    padding-right: 0.5vw;
    order: 2;
  }

  .SRDate, .IRDate {
    width: 7%;
  }
  
  .Notes {
    width: 12%;
  }

  .infoTableHeader button {
    white-space: nowrap;
  }

  #recommendationForm {
    order: 1;
    display: block;
    height: 60vh;
  }

  #formTableContainer {
    flex-wrap: nowrap;
    height: 60vh;
  }

  #notes {
    width: 11vw;
    height: 20vh;
  }
}