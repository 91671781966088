/* Attaching Tutorgen fonts */
@font-face {
  font-family: OpenSans-Light;
  src: url('./fonts/OpenSans-Light.ttf') format('truetype');
}
  
/* Attaching Tutorgen fonts */
@font-face {
  font-family: Rajdhani-Bold;
  src: url('./fonts/Rajdhani-Bold.ttf') format('truetype');
}

#welcome {
  position: absolute;
  font-family: Rajdhani-Bold;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20%;
  margin: auto;
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#welcome sup {
  font-size: 60%;
}

#welcome h1 {
  color: rgb(0,74,124);
}

#welcome h4 {
  color: rgb(0,74,124);
}

#qsLoginBtn {
  background-color: rgb(148, 214, 220);
  border-color: rgb(0,74,124);
  color: rgb(0,74,124);
}
